import { Col, Row } from 'antd';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from '~/common/hooks/useTranslation';
import Button from '~/components/Button';
import SvgIcon from '~/components/SvgIcon';
import { ROUTE_PATH } from '~/routes/route.constant';
import { LayoutCommon } from '~/styles/genaralStyled';
import { services } from './dataGame';

const RemoteSoftware = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [serviceIndex, setServiceIndex] = useState(0);

  return (
    <div>
      <LayoutCommon>
        <div className='gap-20 flex items-center max-md:flex-col max-md:items-stretch max-md:gap-0'>
          <div className='w-full md:w-2/3'>
            <div className='text-neutral-800 text-[36px] font-bold self-stretch w-full'>
              {t('other.Game Development')}
            </div>
            <div className='text-[#262626] text-base leading-7 self-stretch my-8 w-full'>
              {t(
                'services.service.We provide full-cycle game development services, encompassing concept art, visuals, development, and post-production support. Our expert team transforms your ideas into groundbreaking video games.'
              )}
            </div>
            <div className='flex justify-center md:justify-start'>
              <Button
                className='mt-4'
                onClick={() => {
                  navigate(ROUTE_PATH.CONTACT_US);
                }}
              >
                {t('common.btn.contactUs')}
              </Button>
            </div>
          </div>
          <div className='flex justify-center w-full my-4 md:w-1/3'>
            <img
              src='/images/servicesImg/game_development.png'
              width={'100%'}
              alt=''
              className='aspect-[1.39] object-contain object-center w-ful h-[342px] max-w-[342px]  '
            />
          </div>
        </div>
      </LayoutCommon>
      <div
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/images/servicesImg/toolTechnology.png)`
        }}
        className=''
      >
        <LayoutCommon className='!py-[42px]'>
          <Row gutter={[0, 24]}>
            <Col span={24}>
              <div className='text-white text-4xl font-semibold leading-[58.68px] my-2'>
                {t('services.whyC1')}
              </div>
            </Col>
            <Col xl={12} md={12} sm={12} xs={24}>
              <div className='w-full flex flex-col'>
                <div className='mb-4'>
                  <div className='flex gap-3'>
                    <SvgIcon name='icon-fe-check' />
                    <div className='text-white text-base font-semibold leading-6 grow'>
                      {t('services.service.Competitive Cost')}
                    </div>
                  </div>
                  <div className='text-zinc-300 text-base leading-6 self-stretch mt-3 max-md:max-w-full'>
                    <ul className='list-disc pl-7'>
                      <li>{t('services.service.Cost-efficient.')}</li>
                      <li>
                        {t(
                          'services.service.Delivered game projects at 40% of the anticipated cost.'
                        )}
                      </li>
                    </ul>
                  </div>
                </div>

                <div>
                  <div className='flex gap-3'>
                    <SvgIcon name='icon-fe-check' />
                    <div className='text-white text-base font-semibold leading-6 grow'>
                      {t('services.service.Full Of Services')}
                    </div>
                  </div>
                  <div className='text-zinc-300 text-base leading-6 self-stretch mt-3 max-md:max-w-full'>
                    <ul className='list-disc pl-7'>
                      <li>
                        {t(
                          'services.service.Offer a comprehensive range of services, spanning from 3D and 2D game development to art production and robust post-release support.'
                        )}
                      </li>
                      <li>
                        {t(
                          'services.service.Deliver all-encompassing solutions in one place, empowering you to launch your game ahead of competitors.'
                        )}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </Col>
            <Col xl={12} md={12} sm={12} xs={24}>
              <div className='w-full flex flex-col'>
                <div className='flex gap-3'>
                  <SvgIcon name='icon-fe-check' />
                  <div className='text-white text-base font-semibold leading-6 grow'>
                    {t('services.service.High-quality Solutions')}
                  </div>
                </div>
                <div className='text-zinc-300 text-base leading-6 self-stretch mt-3 max-md:max-w-full'>
                  <ul className='list-disc pl-7'>
                    <li>
                      {t(
                        'services.service.Provide cutting-edge game development solutions of the utmost quality.'
                      )}
                    </li>
                    <li>
                      {t(
                        'services.service.Utilize top-tier talent and the latest approaches, employing leading technology and best practices to create games that make a lasting impression.'
                      )}
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        </LayoutCommon>
      </div>

      <LayoutCommon>
        <div>
          <div className='text-neutral-800 text-4xl text-center font-semibold leading-[59px] mt-8  py-7'>
            {t('other.Categories')}
          </div>
          <div className='flex justify-between w-full gap-2 flex-wrap'>
            {services.map((serviceItem, idx) => {
              const formattedCategory = t(
                `services.service.${serviceItem.category}`
              ).replace(/Development|開発/, (match) => `<br>${match.trim()}`);
              return (
                <div
                  key={idx}
                  onClick={() => setServiceIndex(idx)}
                  className={`${
                    idx === serviceIndex ? 'text-orange-600' : 'text-[#262626]'
                  } w-[160px] h-[106px] flex justify-center cursor-pointer whitespace-nowrap text-center text-base font-bold px-4 py-7 rounded-[10px] border border-solid shadow-[0_0_24px_0_rgba(0,0,0,0.12)]`}
                  dangerouslySetInnerHTML={{ __html: formattedCategory }}
                />
              );
            })}
          </div>
        </div>

        <div className='bg-slate-100 mt-6 px-20 py-3 rounded-xl max-md:px-5'>
          <div className='gap-5 flex max-md:flex-col w-full max-md:gap-0'>
            <div className='flex flex-col justify-center text-black text-lg my-auto max-md:mt-10 w-full md:w-2/3 max-md:ml-0'>
              <ul className=' list-disc sm'>
                {services[serviceIndex].technical.map((item, idx) => {
                  return (
                    <li key={idx} className='leading-[40px] w-full'>
                      {t(`services.service.${item}`)}
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className='flex justify-center ml-5 w-full md:w-1/3'>
              <img
                loading='lazy'
                alt=''
                src={services[serviceIndex].img}
                className='object-contain object-center w-full max-w-[326px] overflow-hidden grow max-md:mt-10'
              />
            </div>
          </div>
        </div>
        <div className='mt-10 mb-20'>
          <div className='text-neutral-800 text-4xl text-center font-semibold leading-[59px]  mt-8  py-7'>
            {t('services.service.Workflow')}
          </div>

          <Row gutter={[16, 16]} className='mb-10'>
            <Col sm={24} md={12} xl={8} className='flex justify-center'>
              <div className='flex flex-col'>
                <div className='items-stretch flex grow flex-col max-md:mt-7'>
                  <img
                    loading='lazy'
                    alt=''
                    src='https://cdn.builder.io/api/v1/image/assets/TEMP/2ec63bd16c8a1d29d89f93fbabe1e4449c5843dedb8e8c872e72d269510c2e31?'
                    className='aspect-square object-contain object-center w-[100px] overflow-hidden self-center max-w-full'
                  />
                  <div className='text-neutral-800 text-center text-base font-bold leading-6 capitalize self-center mt-4'>
                    {t('services.service.Research & Analysis')}
                  </div>
                  <div className='text-neutral-800 text-center text-sm leading-6 mt-2'>
                    {t(
                      'services.service.Our experts conduct industry research, define a vision, target audience, and establish key game concepts.'
                    )}
                  </div>
                </div>
              </div>
            </Col>
            <Col sm={24} md={12} xl={8} className='flex justify-center'>
              <div className='flex flex-col '>
                <div className='items-stretch flex grow flex-col max-md:mt-7'>
                  <img
                    loading='lazy'
                    alt=''
                    src='https://cdn.builder.io/api/v1/image/assets/TEMP/51a9cad0aba24766bc45b46a12cd836baa313008ed20734dd37dd9ef9aeba656?'
                    className='aspect-square object-contain object-center w-[100px] overflow-hidden self-center max-w-full'
                  />
                  <div className='text-neutral-800 text-center text-base font-bold leading-6 capitalize self-center mt-4'>
                    {t('services.service.Goal Setting')}
                  </div>
                  <div className='text-neutral-800 text-center text-sm leading-6 mt-2'>
                    {t(
                      'services.service.Refining concepts, our team outlines visuals, gameplay mechanics, and creates a comprehensive Game Design Document (GDD).'
                    )}
                    <br />
                  </div>
                </div>
              </div>
            </Col>
            <Col sm={24} md={12} xl={8} className='flex justify-center'>
              <div className='flex flex-col'>
                <div className='items-center flex grow flex-col max-md:mt-7'>
                  <img
                    loading='lazy'
                    alt=''
                    src='https://cdn.builder.io/api/v1/image/assets/TEMP/0f50cde8c0711d4392607c7527f802aba618545af914886686bfed13e028f5fd?'
                    className='aspect-square object-contain object-center w-[100px] overflow-hidden max-w-full'
                  />
                  <div className='text-neutral-800 text-center text-base font-bold leading-6 capitalize self-stretch mt-4'>
                    {t('services.service.Production')}
                  </div>
                  <div className='text-neutral-800 text-center text-sm leading-6 self-stretch mt-2'>
                    {t(
                      'services.service.Artists and developers collaborate on development, game art, animation, and level design for a cohesive experience.'
                    )}
                  </div>
                </div>
              </div>
            </Col>
            <Col sm={24} md={12} xl={8} className='flex justify-center'>
              <div className='flex flex-col'>
                <div className='items-center flex grow flex-col max-md:mt-7'>
                  <img
                    loading='lazy'
                    alt=''
                    src='https://cdn.builder.io/api/v1/image/assets/TEMP/06e091226627df46c964f8ec05829051196f7402d594120dd117a910c10939a3?'
                    className='aspect-square object-contain object-center w-[100px] overflow-hidden max-w-full'
                  />
                  <div className='text-neutral-800 text-center text-base font-bold leading-6 capitalize self-stretch mt-4'>
                    {t('services.service.Testing')}
                  </div>
                  <div className='text-neutral-800 text-center text-sm leading-6 self-stretch mt-2'>
                    {t(
                      'services.service.Rigorous quality assurance includes A/B testing and bug identification for a polished product.'
                    )}
                  </div>
                </div>
              </div>
            </Col>
            <Col sm={24} md={12} xl={8} className='flex justify-center'>
              <div className='flex flex-col'>
                <div className='items-center flex grow flex-col max-md:mt-7'>
                  <img
                    loading='lazy'
                    alt=''
                    src='/images/listService/step-5.png'
                    className='aspect-square object-contain object-center w-[100px] overflow-hidden max-w-full'
                  />
                  <div className='text-neutral-800 text-center text-base font-bold leading-6 capitalize self-stretch mt-4'>
                    {t('services.service.Launch')}
                  </div>
                  <div className='text-neutral-800 text-center text-sm leading-6 self-stretch mt-2'>
                    {t(
                      'services.service.We manage game launches, marketing, community building, and DevOps for a smooth release.'
                    )}
                  </div>
                </div>
              </div>
            </Col>
            <Col sm={24} md={12} xl={8} className='flex justify-center'>
              <div className='flex flex-col'>
                <div className='items-center flex grow flex-col max-md:mt-7'>
                  <img
                    loading='lazy'
                    alt=''
                    src='/images/listService/step-6.png'
                    className='aspect-square object-contain object-center w-[100px] overflow-hidden max-w-full'
                  />
                  <div className='text-neutral-800 text-center text-base font-bold leading-6 capitalize self-stretch mt-4'>
                    {t('services.service.Support & Promotion')}
                  </div>
                  <div className='text-neutral-800 text-center text-sm leading-6 self-stretch mt-2'>
                    {t(
                      'services.service.Post-release, our analytics gather insights, QA collects feedback, leading to data-driven updates and events for a prolonged game life.'
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </LayoutCommon>
    </div>
  );
};

export default RemoteSoftware;
