import React from 'react';
import { useMutation } from '@apollo/client';
import { Col, Form, Input, Row } from 'antd';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';
import { useTranslation } from '~/common/hooks/useTranslation';
import { listServices } from '~/constants';
import { CREATE_SUBSCRIBER } from '~/graphql/ContactUs.graphql';
import { isValidEmail } from '~/helpers/regex';
import { ROUTE_PATH } from '~/routes/route.constant';
import Button from '../../../components/Button';
import SvgIcon from '../../../components/SvgIcon';
import { FooterWrapper } from '../styled';

const Footer = () => {
  const [form] = Form.useForm();
  const emailValue = Form.useWatch('email', form);
  const [createSubcriber] = useMutation(CREATE_SUBSCRIBER);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const data = [
    {
      title: 'Services',
      subItem: listServices.map((item) => {
        return {
          category: item.title,
          path: item?.path
        };
      })
    },

    {
      title: 'Explore',
      subItem: [
        'News',
        'Insights',
        {
          category: 'Blogs',
          path: ROUTE_PATH.TECH_BLOGS
        },
        {
          category: 'Portfolio',
          path: ROUTE_PATH.PORTFOLIO
        }
      ]
    }
  ];

  const onSearch = () => {
    if (!emailValue) return toast.warning(t('common.required'));
    if (!isValidEmail().test(emailValue))
      return toast.warning(t('common.errorEmail'));

    createSubcriber({
      variables: {
        input: {
          email: emailValue
        }
      },
      onCompleted: () => {
        form.resetFields();
        toast.success('Success');
      },
      onError: (err) => {
        toast.error(err?.message);
      }
    });
  };

  return (
    <FooterWrapper>
      <div className='box-Subscribe'>
        <Row
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/images/explore/bg-input-email.png`
          }}
          className='input-email'
        >
          <Col sm={12} xs={24}>
            <div className='text-1'>
              {t('layout.footer.contact.subscribeNewsletter')}
            </div>
            <div className='text-2'>{t('layout.footer.contact.letsStay')}</div>
          </Col>
          <Col sm={12} xs={24}>
            <Form form={form} autoComplete='off'>
              <Form.Item name={'email'}>
                <Input.Search
                  className='input-search'
                  placeholder={t('layout.footer.contact.enterEmail')}
                  allowClear
                  onSearch={onSearch}
                  enterButton={<Button>{t('common.btn.subscribeNow')}</Button>}
                />
              </Form.Item>
            </Form>
          </Col>
        </Row>
        <Row gutter={[24, 24]}>
          <Col sm={24} xs={24} md={24} lg={8}>
            <div>
              <img
                src={process.env.PUBLIC_URL + '/images/logo1bitlab.png'}
                alt=''
                width={'50%'}
                className='max-w-[200px]'
              />
            </div>
            <div className='context'>
              <div className='p16'>1BITLAB TECHNOLOGY JOINT STOCK COMPANY</div>
              <div className='flex p16'>
                <a
                  href={`mailto:${t('layout.footer.contact.emailAddress')}`}
                  className='flex'
                >
                  <SvgIcon name='mail' style={{ paddingRight: '16px' }} />
                  {t('layout.footer.contact.emailAddress')}
                </a>
              </div>
              <div className='flex p16'>
                <a
                  href={`tel: ${t('layout.footer.contact.phoneNumber')}`}
                  className='flex'
                >
                  <SvgIcon name='phone' style={{ paddingRight: '16px' }} />
                  {t('layout.footer.contact.phoneNumber')}
                </a>
              </div>
              <div className='flex p16'>
                <SvgIcon name='location' style={{ paddingRight: '16px' }} />
                {t('layout.footer.contact.address')}
              </div>
            </div>
          </Col>

          <Col sm={12} xs={24} md={8}>
            <div className='key-option'>
              {t(`layout.footer.${data[0].title}`)}
            </div>

            {data[0].subItem.map((item2) => (
              <div
                key={item2?.category || item2}
                className='flex cursor-pointer'
              >
                <SvgIcon name='iconly' />
                <div
                  className='sub-option'
                  onClick={() => {
                    // if (item.title === 'Services') {
                    navigate(item2?.path);
                    // }
                  }}
                >
                  {t(`other.${item2.category || item2}`)}
                </div>
              </div>
            ))}
          </Col>
          <Col sm={12} xs={24} md={4}>
            <div className='key-option'>
              {t(`layout.footer.${data[1].title}`)}
            </div>

            {data[1].subItem.map((item2) => (
              <div
                key={item2?.category || item2}
                className='flex cursor-pointer'
              >
                <SvgIcon name='iconly' />
                <div
                  className='sub-option'
                  onClick={() => {
                    // if (item.title === 'Services') {
                    navigate(item2?.path);
                    // }
                  }}
                >
                  {t(`other.${item2.category || item2}`)}
                </div>
              </div>
            ))}
          </Col>

          <Col sm={12} xs={24} md={4}>
            <div className='followUs'>{t('other.Follow us')}</div>
            <div className='flex items-center mb-3'>
              {/* <SvgIcon name='discord' style={{ marginRight: '25px' }} />
              <SvgIcon name='instagram' style={{ marginRight: '25px' }} /> */}
              <a
                href='https://www.facebook.com/1bitlabTechnologyJSC'
                target='blank'
              >
                <SvgIcon name='fb32px' style={{ marginRight: '25px' }} />
              </a>
              <a href='https://twitter.com/1bitlabtechjsc' target='blank'>
                <SvgIcon name='tw32px' style={{ marginRight: '25px' }} />
              </a>
              <a
                href='https://www.linkedin.com/company/1bitlab-technology'
                target='blank'
              >
                <SvgIcon name='linkedin32' />
              </a>
            </div>
          </Col>
        </Row>
      </div>
    </FooterWrapper>
  );
};

export default Footer;
