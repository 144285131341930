import React, { useEffect, useRef, useState } from 'react';
import { TechStackBoxWrapper } from '../../styled';
import { useTranslation } from '~/common/hooks/useTranslation';
import { LayoutCommon } from '~/styles/genaralStyled';
import { dataTechStackBox } from './datas';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { Card } from 'antd';
const delay = 5000;

const TechStackBox = () => {
  const { t } = useTranslation();
  const [active, setActive] = useState(3);
  const timeoutRef = useRef(null);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  useEffect(() => {
    resetTimeout();

    timeoutRef.current = setTimeout(
      () =>
        setActive((prevIndex) =>
          prevIndex === dataTechStackBox.length - 1 ? 0 : active + 1
        ),
      delay
    );

    return () => {
      resetTimeout();
    };
  }, [active]);
  return (
    <TechStackBoxWrapper>
      <LayoutCommon>
        <div className='text-center mb-6'>
          <div className='about-info-type-2'>{t('expertises.tool.title')}</div>
          <div className='todo-title text-[36px] font-bold'>
            {t('expertises.tool.subTitle')}
          </div>
        </div>

        <Splide
          className='mb-3'
          aria-label='My Favorite Images'
          options={{
            width: '100%',
            // type: 'loop',
            arrows: false,
            pagination: true,
            lazyLoad: true,
            perPage: 7,
            gap: 10,
            breakpoints: {
              1000: {
                perPage: 4
              },
              600: {
                perPage: 2
              }
            }
          }}
        >
          {dataTechStackBox?.map((data, i) => (
            <SplideSlide
              className='p-1'
              key={data?.title}
              style={{ borderRadius: 10 }}
              onClick={() => {
                setActive(i);
              }}
            >
              <Card
                className={`items__tech w-[160px] cursor-pointer text-center h-[80px] font-bold text-[#939393] text-[16px] py-[10px] px-[16px] flex items-center justify-center ${
                  active === i && 'active'
                }`}
                style={{ border: 'none' }}
              >
                {t(`expertises.tool.data.${data?.title}`)}
              </Card>
            </SplideSlide>
          ))}
        </Splide>
        <div className='flex gap-2 flex-wrap justify-center'>
          {dataTechStackBox[active]?.childrend?.map((data, i) => (
            <div
              key={i}
              className='items__tool flex flex-col  items-center justify-center w-[163px] h-[100px] ease-in-out duration-300'
              style={{
                background: '#f2f8fb',
                borderRadius: 10
              }}
            >
              <img
                src={`${data?.img}`}
                alt=''
                className='ease-in-out duration-300'
              />
              <div className='pt-2 leading-6 text-sm'>{data.category}</div>
            </div>
          ))}
        </div>
      </LayoutCommon>
    </TechStackBoxWrapper>
  );
};

export default TechStackBox;
