import React from 'react';
import { LayoutCommon } from '~/styles/genaralStyled';
import { Col, Row } from 'antd';
import { DetailPortfolioWrapper } from '../../styled';
import { useTheme } from '~/common/theme/redux/hooks/useTheme';
import RelevantProject from './relevantProject';
import { useTranslation } from './../../../../common/hooks/useTranslation';

const DetailPortfolio = () => {
  const {
    data: { portfolio }
  } = useTheme();
  const { t } = useTranslation();
  return (
    <DetailPortfolioWrapper>
      <LayoutCommon>
        <div className='gap-20 flex items-center max-md:flex-col max-md:items-stretch max-md:gap-0 mt-9'>
          <div className='w-full md:w-3/5'>
            <div className='text-[#FF5400] text-[16px] self-stretch w-full'>
              {t(`caseStudy.portfolio.data.${portfolio.titlePage}`)}
            </div>
            <div className='text-[#262626] text-[36px] font-bold self-stretch w-full mt-4'>
              {t(`caseStudy.portfolio.data.${portfolio.title}`)}
            </div>
            <div
              class='text-[#595959] text-base leading-7 self-stretch my-8 w-full '
              style={{ fontFamily: 'Poppins' }}
            >
              {Array.isArray(portfolio.des) ? (
                <ul>
                  {portfolio.des.map((feature, index) => (
                    <p key={index}>
                      {t(`caseStudy.portfolio.data.${feature}`)}
                    </p>
                  ))}
                </ul>
              ) : (
                <p>{t(`caseStudy.portfolio.data.${portfolio.des}`)}</p>
              )}
            </div>
          </div>
          <div className='flex justify-center w-full my-4 md:w-2/5'>
            <img
              src={portfolio.img}
              width={'100%'}
              alt=''
              className='aspect-[1.39] object-contain object-center w-full h-[450px] max-w-[450px]  '
            />
          </div>
        </div>
      </LayoutCommon>
      <div className='features'>
        <LayoutCommon>
          <Row gutter={24}>
            <Col xl={12} md={12} sm={12} xs={24}>
              <Col className='featuresTitle'>
                {t('caseStudy.portfolio.data.Main features')}:
              </Col>
              <Col className='mb-[24px] text-[16px] '>
                <ul style={{ listStyleType: 'disc' }}>
                  {portfolio.mainFeature.map((feature, index) => (
                    <li key={index}>
                      {t(`caseStudy.portfolio.data.${feature}`)}
                    </li>
                  ))}
                </ul>
              </Col>
            </Col>
            <Col xl={12} md={12} sm={12} xs={24}>
              <Col className='featuresTitle'>
                {t('caseStudy.portfolio.data.Results')}:
              </Col>
              <Col className='text-[16px]'>
                <ul style={{ listStyleType: 'disc' }}>
                  {portfolio.results.map((feature, index) => (
                    <li key={index}>
                      {t(`caseStudy.portfolio.data.${feature}`)}
                    </li>
                  ))}
                </ul>
              </Col>
            </Col>
          </Row>
        </LayoutCommon>
      </div>
      <LayoutCommon>
        <Row>
          <Col xl={12} md={12} sm={12} xs={24}>
            <div className='flex justify-center w-full my-4 languages'>
              <img
                src='/images/template/detail/Technology.png'
                width={'100%'}
                alt=''
                className='aspect-[1.39] object-contain object-center w-full h-[450px] max-w-[450px]  '
              />
            </div>
          </Col>
          <Col
            xl={12}
            md={12}
            sm={12}
            xs={24}
            style={{
              margin: '24px 0',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center'
            }}
          >
            <Row gutter={24}>
              <Col xl={24} md={24} sm={24} xs={24} className='title'>
                {t('caseStudy.portfolio.data.TECHNOLOGY')}
              </Col>
            </Row>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))',
                gap: '24px'
              }}
            >
              {portfolio.technology.map((feature, index) => (
                <div className='title-content' key={index}>
                  {feature}
                </div>
              ))}
            </div>

            <Row gutter={24} className='mt-2'>
              <Col xl={12} md={12} sm={12} xs={24}>
                <div className='title'>
                  {t('caseStudy.portfolio.data.Industry')}
                </div>
                <div className='content'>
                  {t(`caseStudy.portfolio.data.${portfolio.industry}`)}
                </div>
              </Col>

              <Col xl={12} md={12} sm={12} xs={24}>
                <div className='title'>
                  {t('caseStudy.portfolio.data.Customer')}
                </div>
                <div className='content'>
                  {t(`caseStudy.portfolio.data.${portfolio.customer}`)}
                </div>
              </Col>
            </Row>
            <Row gutter={24} className='mt-2'>
              <Col xl={12} md={12} sm={12} xs={24}>
                <div className='title'>
                {portfolio?.developmentscaleTitle 
                  ? t(`caseStudy.portfolio.data.${portfolio.developmentscaleTitle}`)
                  : ""}
                </div>
                <div className='content'>
                {portfolio?.developmentscale
                  ? t(`caseStudy.portfolio.data.${portfolio.developmentscale}`)
                  : ""}
                </div>
              </Col>

              <Col xl={12} md={12} sm={12} xs={24}>
                <div className='title'>
                  {portfolio?.projectscaleTitle
                    ? t(`caseStudy.portfolio.data.${portfolio.projectscaleTitle}`)
                    : ""}
                </div>
                <div className='content'>{portfolio.projectscale}</div>
              </Col>
            </Row>
          </Col>
        </Row>
      </LayoutCommon>
      <div className='mt-[-130px]'>
        <RelevantProject t={t} />
      </div>
    </DetailPortfolioWrapper>
  );
};

export default DetailPortfolio;
