import { Col, Row } from 'antd';
import React, { useState } from 'react';
import { LayoutCommon } from '~/styles/genaralStyled';
import { PortfolioWrapper } from '../../HomePage/styled';
import { dataPortfolio } from './dataPortfolio';
import SvgIcon from '~/components/SvgIcon';
import { useNavigate } from 'react-router-dom';
import { ROUTE_PATH } from '~/routes/route.constant';
import { useTheme } from '~/common/theme/redux/hooks/useTheme';
const Portfolio = (props) => {
  const { t } = props;
  const { actions } = useTheme();
  const [serviceIndex, setServiceIndex] = useState(0);
  const navigate = useNavigate();
  return (
    <LayoutCommon>
      <PortfolioWrapper className='mb-8 mt-7'>
        <div className='justify-center flex gap-2.5 mt-11 px-5 max-md:mt-10'>
          <div className='about-info-type-2'>
            {t('caseStudy.portfolio.title')}
          </div>
        </div>
        <div className='row-project'>
          <div>
            <div className='our-project'>{t('caseStudy.portfolio.des')}</div>
          </div>
          <Row
            className='w-[210px] md:w-[1000px] menu'
            style={{ marginBottom: '24px' }}
          >
            {dataPortfolio?.map((serviceItem, idx) => (
              <Col
                xl={4}
                md={8}
                sm={12}
                xs={24}
                key={idx}
                onClick={() => setServiceIndex(idx)}
                className={`${
                  idx === serviceIndex ? 'text-orange-600' : 'text-[#262626]'
                } cp text-center`}
              >
                {t(`caseStudy.portfolio.data.${serviceItem.title}`)}
              </Col>
            ))}
          </Row>
        </div>

        <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6'>
          {dataPortfolio[serviceIndex]?.childrend?.map((data, item) => (
            <div
              key={item}
              className='flex col-wrapper'
              onClick={() => {
                actions.onChangeDetail(data);
                const currentPath = window.location.pathname;
                const newPath = currentPath.includes('/ja')
                  ? '/ja/' + ROUTE_PATH.DETAIL_PORTFOLIO
                  : '/' + ROUTE_PATH.DETAIL_PORTFOLIO;
                navigate(newPath);
              }}
            >
              <img src={`${data?.img}`} alt='' className='temp-img cp' />
              <div className='titlehover text-center '>
                <SvgIcon name='linkLine' />
                <p className='font-worksans text-[25px]'>{t(`caseStudy.portfolio.data.${data.title}`)}</p>
              </div>
            </div>
          ))}
        </div>
      </PortfolioWrapper>
    </LayoutCommon>
  );
};

export default Portfolio;
