import { Col, Row } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from '~/common/hooks/useTranslation';
import Button from '~/components/Button';
import SvgIcon from '~/components/SvgIcon';
import { ROUTE_PATH } from '~/routes/route.constant';
import { LayoutCommon } from '~/styles/genaralStyled';

const RDSolutions = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div>
      <LayoutCommon>
        <div className='gap-5 flex items-center max-md:flex-col'>
          <div className='w-full md:w-2/3'>
            <div className='text-neutral-800 text-[36px] font-bold self-stretch w-full'>
              {t('other.R&D Services')}
            </div>
            <div className='text-[#262626] text-base leading-7 self-stretch mt-4 w-full'>
              {t(
                'services.service.Leveraging our profound insights and specialized knowledge, we assist in uncovering innovative business models and custom-tailored services designed to meet your distinct requirements. We craft solutions that directly tackle your specific challenges and opportunities.'
              )}
            </div>
            <Button
              className='mt-4'
              onClick={() => {
                navigate(ROUTE_PATH.CONTACT_US);
              }}
            >
              {t('common.btn.contactUs')}
            </Button>
          </div>
          <div className='w-full md:w-1/3 '>
            <img
              src='/images/servicesImg/rd-solution.png'
              width={'100%'}
              alt=''
              className='aspect-[1.39] object-contain object-center w-full  h-[342px] max-w-[380px]  '
            />
          </div>
        </div>
      </LayoutCommon>
      <div
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/images/servicesImg/toolTechnology.png)`
        }}
        className=''
      >
        <LayoutCommon className='!py-[42px]'>
          <Row gutter={[0, 24]}>
            <Col span={24}>
              <div className='text-white text-4xl font-semibold leading-[58.68px] my-2'>
                {t('services.whyC1')}
              </div>
            </Col>

            <Col xl={12} md={12} sm={12} xs={24}>
              <div className='w-full flex flex-col'>
                <div className='mb-4'>
                  <div className='flex gap-3'>
                    <SvgIcon name='icon-fe-check' />
                    <div className='text-white text-base font-semibold leading-6 grow'>
                      {t('services.service.Best Cost-effectiveness')}
                    </div>
                  </div>
                  <div className='text-zinc-300 text-base leading-6 self-stretch mt-3 max-md:max-w-full'>
                    <ul className='list-disc pl-7'>
                      <li>
                        {t(
                          'services.service.Our solutions deliver high value to the clients while reducing IT costs and other expenses.'
                        )}
                      </li>
                    </ul>
                  </div>
                </div>

                <div>
                  <div className='flex gap-3'>
                    <SvgIcon name='icon-fe-check' />
                    <div className='text-white text-base font-semibold leading-6 grow'>
                      {t('services.service.Technical Quality')}
                    </div>
                  </div>
                  <div className='text-zinc-300 text-base leading-6 self-stretch mt-3 max-md:max-w-full'>
                    <ul className='list-disc pl-7'>
                      <li>
                        {t(
                          'services.service.Our solutions are reliable, secure, and functional.'
                        )}
                      </li>

                      <li>
                        {t(
                          'services.service.We master new technologies and techniques to enhance them.'
                        )}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </Col>
            <Col xl={12} md={12} sm={12} xs={24}>
              <div className='w-full flex flex-col'>
                <div className='flex gap-3'>
                  <SvgIcon name='icon-fe-check' />
                  <div className='text-white text-base font-semibold leading-6 grow'>
                    {t('services.service.Professionalism')}
                  </div>
                </div>
                <div className='text-zinc-300 text-base leading-6 self-stretch mt-3 max-md:max-w-full'>
                  <ul className='list-disc pl-7'>
                    <li>
                      {t(
                        'services.service.We have an expertly specialized team consisting of PhDs who actively work within R&D research departments.'
                      )}
                    </li>

                    <li>
                      {t(
                        'services.service.We boast extensive years of experience in R&D and maintain readiness to embrace emerging technologies.'
                      )}
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        </LayoutCommon>
      </div>

      <LayoutCommon>
        <div className='text-neutral-800 text-4xl text-center font-semibold leading-[59px]  mt-8  py-7'>
          {t('other.Categories')}
        </div>

        <Row gutter={[24, 24]} className='mb-10'>
          <Col xs={24} md={12}>
            <div className='flex flex-col w-full shadow-[0_1px_20px_0_rgba(0,36,125,0.06)] h-full'>
              <span className='shadow-sm bg-white flex grow flex-col w-full p-4 rounded-lg items-start max-md:max-w-full max-md:mt-7'>
                <img
                  loading='lazy'
                  alt=''
                  src='/images/servicesImg/dev-service.png'
                  className='aspect-square object-contain object-center w-[58px]'
                />
                <div className='text-neutral-800 text-base font-bold leading-6 self-stretch mt-4 max-md:max-w-full'>
                  {t('services.service.Professionalism')}
                </div>
                <div className='self-stretch text-zinc-600 text-base leading-6 mt-4 max-md:max-w-full'>
                  {t(
                    'services.service.We develop product improvements for customers and aim to deliver high-quality solutions that meet the needs and expectations of our clients, and generate business value for them.'
                  )}
                </div>
              </span>
            </div>
          </Col>
          <Col xs={24} md={12}>
            <div className='flex flex-col w-full shadow-[0_1px_20px_0_rgba(0,36,125,0.06)] h-full'>
              <span className='shadow-sm bg-white flex grow flex-col w-full p-4 rounded-lg items-start max-md:max-w-full max-md:mt-7'>
                <img
                  loading='lazy'
                  alt=''
                  src='/images/servicesImg/ux-transfor.png'
                  className='aspect-square object-contain object-center w-[58px]'
                />
                <div className='text-neutral-800 text-base font-bold leading-6 self-stretch mt-4 max-md:max-w-full'>
                  {t('services.service.UI/UX transformation')}
                </div>
                <div className='self-stretch text-zinc-600 text-base leading-6 mt-4 max-md:max-w-full'>
                  {t(
                    'services.service.We design sophisticated and intuitive user interfaces (UI) and user experiences (UX) that increase engagement and create lasting impressions.'
                  )}
                </div>
              </span>
            </div>
          </Col>
          <Col xs={24} md={12}>
            <div className='flex flex-col w-full shadow-[0_1px_20px_0_rgba(0,36,125,0.06)] h-full'>
              <span className='shadow-sm bg-white flex grow flex-col w-full p-4 rounded-lg items-start max-md:max-w-full max-md:mt-7'>
                <img
                  loading='lazy'
                  alt=''
                  src='/images/servicesImg/process-automation.png'
                  className='aspect-square object-contain object-center w-[58px]'
                />
                <div className='text-neutral-800 text-base font-bold leading-6 self-stretch mt-4 max-md:max-w-full'>
                  {t('services.service.Process automation')}
                </div>
                <div className='self-stretch text-zinc-600 text-base leading-6 mt-4 max-md:max-w-full'>
                  {t(
                    'services.service.We optimize operations and augment efficiency by automating mundane tasks and workflows, liberating resources for higher-value activities.'
                  )}
                </div>
              </span>
            </div>
          </Col>
          <Col xs={24} md={12}>
            <div className='flex flex-col w-full shadow-[0_1px_20px_0_rgba(0,36,125,0.06)] h-full'>
              <span className='shadow-sm bg-white flex grow flex-col w-full p-4 rounded-lg items-start max-md:max-w-full max-md:mt-7'>
                <img
                  loading='lazy'
                  alt=''
                  src='/images/servicesImg/implementation.png'
                  className='aspect-square object-contain object-center w-[58px] '
                />
                <div className='text-neutral-800 text-base font-bold leading-6 self-stretch mt-4 max-md:max-w-full'>
                  {t(
                    'services.service.Implementation of emerging technologies'
                  )}
                </div>
                <div className='self-stretch text-zinc-600 text-base leading-6 mt-4 max-md:max-w-full'>
                  {t(
                    'services.service.We proficiently integrate emerging technologies, such as AI, IoT, and blockchain, to revolutionize your business processes and services.'
                  )}
                </div>
              </span>
            </div>
          </Col>
        </Row>
      </LayoutCommon>
    </div>
  );
};

export default RDSolutions;
