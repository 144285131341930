const getLocaleFromURL = () => {
  const path = window.location.pathname.split('/')[1];
  return path === 'ja' ? 'ja' : 'en';
};

export const THEME_CONFIG = {
  locale: getLocaleFromURL(),
  currentTheme: 'light',
  service: 0,
  portfolio: localStorage.getItem('portfolioData')
    ? JSON.parse(localStorage.getItem('portfolioData'))
    : null
};
