export const data = [
  {
    avatar: process.env.PUBLIC_URL + '/images/user/user2.png',
    rating: 5,
    comment: `We approached 1BITLAB with a vague experimental project, knowing our requirements weren't fully formed. Not only did they deliver a highly satisfying prototype, but the quality of their personnel was truly impressed. 1BITLAB designed and developed exactly what we needed, without needing constant technical input – just incredible.`,
    position: 'CEO',
    name: 'Software Development Company'
  },
  {
    avatar: process.env.PUBLIC_URL + '/images/user/user2.png',
    rating: 4.5,
    comment:'In crafting our mobile app, 1BITLAB surpassed expectations by not only fulfilling our initial requirements but also suggesting innovative features that significantly improved the user experience. The final product exceeded both our expectations and those of our target audience. We are delighted with the outcome and wholeheartedly recommend 1BITLAB to others.',
    position: 'Co-Founder/ CEO',
    name: 'Game Development Studio'
  },
  {
    avatar: process.env.PUBLIC_URL + '/images/user/user2.png',
    rating: 4.5,
    comment: `Collaborating with 1BITLAB has been a rewarding experience. Their skilled team's proficiency in AI, blockchain, and other tech domains has significantly contributed to our joint projects' success. Their collaborative spirit and commitment to achieving milestones make them a reliable and valued partner.`,
    position: 'CTO',
    name: 'Web3 Development Services Company'
  },
  {
    avatar: process.env.PUBLIC_URL + '/images/user/user2.png',
    rating: 5,
    comment: `Collaborating with 1BITLAB on web and application development was effortlessly smooth. Despite the one-year collaboration, their consistent responsiveness and dedicated efforts to enhance speed and quality made a lasting impact.`,
    position: 'Project Manager',
    name: 'Website and eCommerce Development Company'
  },
  {
    avatar: process.env.PUBLIC_URL + '/images/user/user1.png',
    rating: 4.5,
    comment: `1BITLAB team's expertise and dedication to innovation have added immense value to our collaborative ventures. Their comprehensive understanding of emerging technologies and commitment to excellence make them an ideal partner for achieving shared goals.`,
    name: 'Community Marketing Company',
    position: 'CEO'
  },
  {
    avatar: process.env.PUBLIC_URL + '/images/user/user2.png',
    rating: 4.5,
    comment: `Our project required flexibility and adaptability, and 1BITLAB proved to be an ideal partner. They adeptly embraced our iterative approach, swiftly adapting to changing requirements and priorities. Their impressive troubleshooting skills and creative problem-solving under pressure played a crucial role in the project's success.`,
    name: 'Full-cycle Software Development Company',
    position: 'CTO'
  },
  {
    avatar: process.env.PUBLIC_URL + '/images/user/user3.png',
    rating: 5,
    comment: `As a client of 1BITLAB, I've experienced remarkable service quality and expertise. Their qualified team's ability to deliver tailored solutions aligned perfectly with our business needs. Their approach towards customer satisfaction, coupled with their extensive tech knowledge, makes them a top choice for anyone seeking cutting-edge tech solutions.`,
    name: 'Online Retails Company',
    position: 'CEO'
  },
  {
    avatar: process.env.PUBLIC_URL + '/images/user/user3.png',
    rating: 5,
    comment: `Working alongside 1BITLAB has been a strategic move for our organization. Their adeptness in the tech landscape and commitment to achieving common objectives have yielded impactful outcomes. Their integrity, coupled with a forward-thinking mindset, makes them an invaluable partner in our growth journey.`,
    name: 'Innovative Education Companies',
    position: 'Founder/ CEO'
  }
];
