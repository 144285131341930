import { Col, Row } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from '~/common/hooks/useTranslation';
import { useTheme } from '~/common/theme/redux/hooks/useTheme';
import { listServices } from '~/constants';
import { DivCustom, ToDoListWrapper } from '~/pages/HomePage/styled';
import { LayoutCommon } from '~/styles/genaralStyled';

const ToDoList = ({ style }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { actions } = useTheme();

  return (
    <DivCustom style={style}>
      <LayoutCommon>
        <ToDoListWrapper>
          <div className='about-info-type-2'>{t('homePage.service.title')}</div>
          <div className='todo-title'>{t('homePage.service.des')}</div>
          <Row gutter={[24, 24]} className='flex justify-center'>
            {listServices.map((item, idx) => {
              return (
                <Col xl={8} md={12} sm={12} xs={24} key={`todo-${idx + 1}`}>
                  <div
                    className='flex todo-item items-center  h-full ease-in-out duration-300'
                    onClick={() => {
                      actions.onChangeService(idx);
                      navigate(item?.path);
                    }}
                  >
                    <img
                      src={item.img}
                      alt=''
                      width={80}
                      className='ease-in-out duration-300 '
                    />
                    <div className='item-title'>{t(`other.${item.title}`)}</div>
                    <div className='item-content'>{t(`homePage.service.data.${item.description}`)}</div>
                  </div>
                </Col>
              );
            })}
          </Row>
        </ToDoListWrapper>
      </LayoutCommon>
    </DivCustom>
  );
};

ToDoList.propTypes = {
  style: PropTypes.object
};

export default ToDoList;
