import { Col, Row } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from '~/common/hooks/useTranslation';
import Button from '~/components/Button';
import SvgIcon from '~/components/SvgIcon';
import { ROUTE_PATH } from '~/routes/route.constant';
import { LayoutCommon } from '~/styles/genaralStyled';
import { advantages } from './data';

const RemoteSoftware = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div>
      <LayoutCommon>
        <div className='gap-20 flex items-center max-md:flex-col max-md:items-stretch max-md:gap-0'>
          <div className='w-full md:w-2/3'>
            <div className='text-neutral-800 text-[36px] font-bold self-stretch w-full'>
              {t('other.Remote Software Engineer Services')}
            </div>
            <div className='text-[#262626] text-base leading-7 self-stretch my-8 w-full'>
              {t(
                'services.service.Bridge geographical gaps effortlessly with our Remote Software Engineering Services (SES). Gain access to our pool of skilled professionals who can collaborate remotely, ensuring the continuity of development, testing, and project management.'
              )}
            </div>
            <div className='flex justify-center md:justify-start'>
              <Button
                className='mt-4'
                onClick={() => {
                  navigate(ROUTE_PATH.CONTACT_US);
                }}
              >
                {t('common.btn.contactUs')}
              </Button>
            </div>
          </div>
          <div className='flex justify-center w-full my-4 md:w-1/3'>
            <img
              src='/images/servicesImg/remote_software.png'
              width={'100%'}
              alt=''
              className='aspect-[1.39] object-contain object-center w-ful h-[342px] max-w-[342px]  '
            />
          </div>
        </div>
      </LayoutCommon>
      <div
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/images/servicesImg/toolTechnology.png)`
        }}
        className=''
      >
        <LayoutCommon className='!py-[42px]'>
          <div className='gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0'>
            <div className='items-stretch flex flex-col w-full   max-w-[400px] md:w-1/2'>
              <div className='text-white text-4xl font-semibold leading-[58.68px] my-2'>
                {t('services.whyC1')}
              </div>
            </div>
          </div>

          <Row gutter={[48, 32]}>
            {advantages.map((advantageItem) => {
              return (
                <Col xl={12} md={12} sm={24} xs={24}>
                  <div className='flex gap-3'>
                    <SvgIcon name='icon-fe-check' />
                    <div className='text-white text-base font-semibold leading-6 grow'>
                      {t(`services.service.${advantageItem.title}`)}
                    </div>
                  </div>
                  <div className='text-zinc-300 text-base leading-6 self-stretch mt-3 max-md:max-w-full'>
                    <ul className='list-disc pl-7'>
                      {advantageItem.des.map((item) => {
                        return (
                          <li key={item}>{t(`services.service.${item}`)}</li>
                        );
                      })}
                    </ul>
                  </div>
                </Col>
              );
            })}
          </Row>
        </LayoutCommon>
      </div>

      <LayoutCommon>
        <div className='text-neutral-800 text-4xl text-center font-semibold leading-[59px] mt-8  py-7'>
          {t('other.Categories')}
        </div>

        <div className='gap-5 flex max-md:flex-col  max-md:gap-0'>
          <div className='flex flex-col  w-full lg-w-[33%] shadow-[0_1px_20px_0_rgba(0,36,125,0.06)]'>
            <span className='items-stretch shadow-sm bg-white flex grow flex-col w-full px-4 py-5 rounded-lg max-md:mt-6'>
              <img
                loading='lazy'
                alt=''
                src='/images/servicesImg/project-team.png'
                className='aspect-square object-contain object-center w-[58px] overflow-hidden self-center max-w-full'
              />
              <div className='text-neutral-800 text-base font-bold leading-6 self-center whitespace-nowrap mt-4'>
                {t('services.service.Full Project Team')}
              </div>
              <div className='text-zinc-600 text-center text-base leading-6 mt-4'>
                {t(
                  'services.service.The best structure offshore team based on your needs, including Project Manager, Developer, Tester, Technical Architect, and Designer.'
                )}
              </div>
            </span>
          </div>
          <div className='flex flex-col w-full   max-w-[400px] lg-w-[33%] shadow-[0_1px_20px_0_rgba(0,36,125,0.06)]'>
            <span className='items-stretch shadow-sm bg-white flex grow flex-col w-full pt-4 pb-12 px-4 rounded-lg max-md:mt-6'>
              <img
                loading='lazy'
                alt=''
                src='/images/servicesImg/engineer-team.png'
                className='aspect-square object-contain object-center w-[58px] overflow-hidden self-center max-w-full'
              />
              <div className='text-neutral-800 text-base font-bold leading-6 self-center whitespace-nowrap mt-4'>
                {t('services.service.Fullstack Engineer Team')}
              </div>
              <div className='text-zinc-600 text-center text-base leading-6 mt-4 mb-5'>
                {t(
                  'services.service.Reduce your capital and operating costs while modernizing your IT.'
                )}
              </div>
            </span>
          </div>
          <div className='flex flex-col w-full   max-w-[400px] lg-w-[33%] shadow-[0_1px_20px_0_rgba(0,36,125,0.06)]'>
            <span className='items-stretch shadow-sm bg-white flex grow flex-col w-full pt-4 pb-12 px-4 rounded-lg max-md:mt-6'>
              <img
                loading='lazy'
                alt=''
                src='/images/servicesImg/QA-team.png'
                className='aspect-square object-contain object-center w-[58px] overflow-hidden self-center max-w-full'
              />
              <div className='text-neutral-800 text-base font-bold leading-6 self-center whitespace-nowrap mt-4'>
                {t('services.service.Quality Assurance Team')}
              </div>
              <div className='text-zinc-600 text-center text-base leading-6 mt-4 mb-5'>
                {t(
                  'services.service.We deliver stand-alone and integrated testing services to various customers.'
                )}
              </div>
            </span>
          </div>
        </div>

        <div>
          <div className='text-neutral-800 text-4xl text-center font-semibold leading-[59px] mt-8  py-7'>
            {t('services.service.Workflow')}
          </div>

          <Row gutter={[16, 16]} className='mb-10'>
            <Col sm={24} md={12} xl={8} className='flex justify-center'>
              <div className='flex flex-col w-full  max-w-[400px]'>
                <div className='items-stretch flex grow flex-col max-md:mt-7'>
                  <img
                    loading='lazy'
                    alt=''
                    src='https://cdn.builder.io/api/v1/image/assets/TEMP/2ec63bd16c8a1d29d89f93fbabe1e4449c5843dedb8e8c872e72d269510c2e31?'
                    className='aspect-square object-contain object-center w-[100px] overflow-hidden self-center max-w-full'
                  />
                  <div className='text-neutral-800 text-center text-base font-bold leading-6 capitalize self-center mt-4'>
                    {t('services.service.Finding the right people')}
                  </div>
                  <div className='text-neutral-800 text-center text-sm leading-6 mt-2'>
                    {t(
                      'services.service.Our skilled Recruiting specialists scout candidates tailored to your needs from our pool of available programmers and the wider job market.'
                    )}
                  </div>
                </div>
              </div>
            </Col>
            <Col sm={24} md={12} xl={8} className='flex justify-center'>
              <div className='flex flex-col  w-full  max-w-[400px]'>
                <div className='items-stretch flex grow flex-col max-md:mt-7'>
                  <img
                    loading='lazy'
                    alt=''
                    src='https://cdn.builder.io/api/v1/image/assets/TEMP/51a9cad0aba24766bc45b46a12cd836baa313008ed20734dd37dd9ef9aeba656?'
                    className='aspect-square object-contain object-center w-[100px] overflow-hidden self-center max-w-full'
                  />
                  <div className='text-neutral-800 text-center text-base font-bold leading-6 capitalize self-center mt-4'>
                    {t('services.service.Refined Selection')}
                  </div>
                  <div className='text-neutral-800 text-center text-sm leading-6 mt-2'>
                    {t(
                      'services.service.Our Human Resource Department, along with Senior Technical and Management staff, meticulously vet and choose candidates best suited for your project.'
                    )}
                    <br />
                  </div>
                </div>
              </div>
            </Col>
            <Col sm={24} md={12} xl={8} className='flex justify-center'>
              <div className='flex flex-col w-full   max-w-[400px]'>
                <div className='items-center flex grow flex-col max-md:mt-7'>
                  <img
                    loading='lazy'
                    alt=''
                    src='https://cdn.builder.io/api/v1/image/assets/TEMP/0f50cde8c0711d4392607c7527f802aba618545af914886686bfed13e028f5fd?'
                    className='aspect-square object-contain object-center w-[100px] overflow-hidden max-w-full'
                  />
                  <div className='text-neutral-800 text-center text-base font-bold leading-6 capitalize self-stretch mt-4'>
                    {t('services.service.Interview Process')}
                  </div>
                  <div className='text-neutral-800 text-center text-sm leading-6 self-stretch mt-2'>
                    {t(
                      'services.service.Engage in online interviews with full stack developers to handpick the ideal professionals for your projects. A trial period is offered for any new developer.'
                    )}
                    <br />
                    <br />
                  </div>
                </div>
              </div>
            </Col>
            <Col sm={24} md={12} xl={8} className='flex justify-center'>
              <div className='flex flex-col w-full   max-w-[400px]'>
                <div className='items-center flex grow flex-col max-md:mt-7'>
                  <img
                    loading='lazy'
                    alt=''
                    src='https://cdn.builder.io/api/v1/image/assets/TEMP/06e091226627df46c964f8ec05829051196f7402d594120dd117a910c10939a3?'
                    className='aspect-square object-contain object-center w-[100px] overflow-hidden max-w-full'
                  />
                  <div className='text-neutral-800 text-center text-base font-bold leading-6 capitalize self-stretch mt-4'>
                    {t('services.service.Starting development')}
                  </div>
                  <div className='text-neutral-800 text-center text-sm leading-6 self-stretch mt-2'>
                    {t(
                      'services.service.The selected developers become integral members of your team. Directly assign tasks and maintain open communication throughout your development process.'
                    )}
                  </div>
                </div>
              </div>
            </Col>
            <Col sm={24} md={12} xl={8} className='flex justify-center'>
              <div className='flex flex-col w-full   max-w-[400px]'>
                <div className='items-center flex grow flex-col max-md:mt-7'>
                  <img
                    loading='lazy'
                    alt=''
                    src='/images/listService/step-5.png'
                    className='aspect-square object-contain object-center w-[100px] overflow-hidden max-w-full'
                  />
                  <div className='text-neutral-800 text-center text-base font-bold leading-6 capitalize self-stretch mt-4'>
                    {t('services.service.Direct Reporting')}
                  </div>
                  <div className='text-neutral-800 text-center text-sm leading-6 self-stretch mt-2'>
                    {t(
                      'services.service.All hired engineers report directly to you, adhering to your instructions and project timelines.'
                    )}
                  </div>
                </div>
              </div>
            </Col>
            <Col sm={24} md={12} xl={8} className='flex justify-center'>
              <div className='flex flex-col w-full   max-w-[400px]'>
                <div className='items-center flex grow flex-col max-md:mt-7'>
                  <img
                    loading='lazy'
                    alt=''
                    src='/images/listService/step-6.png'
                    className='aspect-square object-contain object-center w-[100px] overflow-hidden max-w-full'
                  />
                  <div className='text-neutral-800 text-center text-base font-bold leading-6 capitalize self-stretch mt-4'>
                    {t('services.service.Performance Assessment')}
                  </div>
                  <div className='text-neutral-800 text-center text-sm leading-6 self-stretch mt-2'>
                    {t(
                      'services.service.Conduct monthly or quarterly evaluations of our employed developers to foster continuous improvement and refine our services.'
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </LayoutCommon>
    </div>
  );
};

export default RemoteSoftware;
