import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

const RouteMetadata = () => {
  const location = useLocation();

  // Xác định ngôn ngữ từ URL
  const isJapanese = location.pathname.startsWith('/ja');
  const cleanPath = location.pathname.replace(/^\/ja/, '') || '/';
  const lang = isJapanese ? 'ja' : 'en';

  // Metadata cho tiếng Anh
  const metadataMap = {
    '/': {
      title: 'Top Development Hub In Asia For SMEs & Startups | 1BITLAB',
      description: '1BITLAB delivers the full-cycle software development services. We are a cutting-edge technology company, committed to delivering software solutions in diverse industries with agility, top-notch quality, and cost efficiency.',
      keywords: ''
    },
    '/about-us': {
      title: 'About Us | 1BITLAB',
      description: 'Learn more about 1BITLAB and our mission.',
      keywords: 'About, Company, 1BITLAB'
    },
    '/managed-services': {
      title: 'Managed Services | 1BITLAB',
      description: 'Our managed services help you scale efficiently.',
      keywords: 'Managed Services, IT Support'
    },
    '/career': {
      title: 'Career | 1BITLAB',
      description: 'Join our team and grow your career at 1BITLAB.',
      keywords: 'Jobs, Career, Work'
    },
    '/explore-news': {
      title: 'News | 1BITLAB',
      description: 'Stay updated with the latest news from 1BITLAB.',
      keywords: 'News, Updates'
    },
    '/portfolio': {
      title: 'Portfolio | 1BITLAB',
      description: 'Explore our portfolio of successful projects.',
      keywords: 'Projects, Portfolio'
    },
    '/contact-us': {
      title: 'Contact Us | 1BITLAB',
      description: 'Get in touch with us for any inquiries.',
      keywords: 'Contact, Support'
    },
    '/expertise': {
      title: 'Expertise | 1BITLAB',
      description: 'Our expertise spans multiple industries and technologies.',
      keywords: 'Expertise, Services'
    },
    '/on-demand-software-development': {
      title: 'On-demand Software Development | 1BITLAB',
      description: 'Get custom software developed on demand.',
      keywords: 'Software Development, Custom Solutions'
    },
    '/remote-software': {
      title: 'Remote Software | 1BITLAB',
      description: 'Our remote software solutions ensure seamless workflow.',
      keywords: 'Remote, Software'
    },
    '/r&d-solutions': {
      title: 'R&D Solutions | 1BITLAB',
      description: 'We provide cutting-edge R&D solutions.',
      keywords: 'Research, Development'
    },
    '/game-development': {
      title: 'Game Development | 1BITLAB',
      description: 'We create innovative and engaging games.',
      keywords: 'Game Development, Gaming'
    },
    '/detail-portfolio': {
      title: 'Portfolio | 1BITLAB',
      description: 'Detailed view of our past projects.',
      keywords: 'Portfolio, Projects'
    },
    '/tech-blogs': {
      title: 'Tech Blogs | 1BITLAB',
      description: 'Read insightful articles from our tech team.',
      keywords: 'Tech, Blog, Development'
    }
  };

  // Metadata cho tiếng Nhật
  const metadataJaMap = {
    '/': {
      title: 'アジアのトップ開発ハブ | 1BITLAB',
      description: '1BITLABは、フルサイクルのソフトウェア開発サービスを提供しています。最先端の技術会社として、さまざまな業界向けのソリューションを提供します。',
      keywords: ''
    },
    '/about-us': {
      title: '会社概要 | 1BITLAB',
      description: '1BITLABのミッションとビジョンについてご紹介します。',
      keywords: '会社概要, 企業情報, 1BITLAB'
    },
    '/managed-services': {
      title: 'マネージドサービス | 1BITLAB',
      description: 'スケール効率を高めるマネージドサービス。',
      keywords: 'マネージドサービス, ITサポート'
    },
    '/career': {
      title: '採用情報 | 1BITLAB',
      description: '1BITLABでのキャリアを築きませんか？',
      keywords: '求人, キャリア, 仕事'
    },
    '/explore-news': {
      title: 'ニュース | 1BITLAB',
      description: '1BITLABの最新ニュースをチェック。',
      keywords: 'ニュース, 最新情報'
    },
    '/portfolio': {
      title: 'ポートフォリオ | 1BITLAB',
      description: '私たちの成功事例を紹介します。',
      keywords: 'プロジェクト, ポートフォリオ'
    },
    '/contact-us': {
      title: 'お問い合わせ | 1BITLAB',
      description: 'お問い合わせはこちらから。',
      keywords: 'コンタクト, サポート'
    },
    '/expertise': {
      title: '専門分野 | 1BITLAB',
      description: '幅広い分野の専門知識をご提供。',
      keywords: '専門知識, サービス'
    },
    '/on-demand-software-development': {
      title: 'オンデマンド開発 | 1BITLAB',
      description: 'カスタムソフトウェア開発を提供します。',
      keywords: 'ソフトウェア開発, カスタムソリューション'
    },
    '/remote-software': {
      title: 'リモートソフトウェア | 1BITLAB',
      description: 'スムーズなワークフローを実現するソリューション。',
      keywords: 'リモート, ソフトウェア'
    },
    '/r&d-solutions': {
      title: 'R&Dソリューション | 1BITLAB',
      description: '最先端の研究開発ソリューション。',
      keywords: '研究, 開発'
    },
    '/game-development': {
      title: 'ゲーム開発 | 1BITLAB',
      description: '革新的なゲームを制作。',
      keywords: 'ゲーム開発, ゲーム'
    },
    '/detail-portfolio': {
      title: 'ポートフォリオ詳細 | 1BITLAB',
      description: '過去のプロジェクトを詳しく紹介。',
      keywords: 'ポートフォリオ, プロジェクト'
    },
    '/tech-blogs': {
      title: 'テックブログ | 1BITLAB',
      description: 'テックチームの洞察に満ちた記事。',
      keywords: 'テクノロジー, ブログ, 開発'
    }
  };

  // Chọn metadata theo ngôn ngữ
  const metadata = (isJapanese ? metadataJaMap : metadataMap)[cleanPath] || {
    title: lang === 'ja' ? 'ページが見つかりません' : 'Page Not Found',
    description: lang === 'ja' ? 'お探しのページは存在しません。' : 'The page you requested does not exist.',
    keywords: '404, not found'
  };

  return (
    <Helmet>
      <title>{metadata.title}</title>
      <meta name="description" content={metadata.description} />
      <meta name="keywords" content={metadata.keywords} />
      <meta property="og:title" content={metadata.title} />
      <meta property="og:description" content={metadata.description} />
    </Helmet>
  );
};

export default RouteMetadata;