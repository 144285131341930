import React from 'react';
import { CapabilitiWrapper } from '../../styled';
import { Col, Row } from 'antd';
import { dataCapabiliti } from './data';

const Capabiliti = (props) => {
  const { t } = props;
  return (
    <CapabilitiWrapper>
      <Row>
        <Col xl={12} md={24} sm={24} xs={24} className='flex justify-center'>
          <img
            className='h-full w-full object-cover max-w-[580px] mx-auto'
            src={`${process.env.PUBLIC_URL}/images/capability/capabiliti.jpg`}
            alt=''
          />
        </Col>
        <Col className='relative' xl={12} md={24} sm={24} xs={24}>
          <div className='md:pl-4 pt-5 lg:pt-0'>
            <div className='capability'>
              {t('homePage.ourCapabilities.title')}
            </div>
            <div className='capability-title mt-[15px] sm:my-4'>
              {t('homePage.ourCapabilities.why1bitlab')}
            </div>
            <div className='flex flex-col gap-6'>
              {dataCapabiliti?.map((data) => (
                <div key={data?.icon} className='flex gap-4'>
                  <div className='bg-[#FF5400] min-w-[56px] h-[56px] rounded-[5.25px] flex items-center justify-center'>
                    <img
                      className='w-[20px] h-[20px]'
                      src={`../svg/Capabiliti/${data?.icon}.svg`}
                      alt=''
                    />
                  </div>

                  <div className='flex flex-col justify-center'>
                    <div className='text-[20px] font-bold text-[#2A3342]'>
                      {t(`homePage.ourCapabilities.data.${data?.title}`)}
                    </div>
                    <div className='text-[16px] text-[#7B7B7B]'>
                      {t(`homePage.ourCapabilities.data.${data?.des}`)}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Col>
      </Row>
    </CapabilitiWrapper>
  );
};

export default Capabiliti;
