import { Col, Form, Input, Modal, Row, Select, Spin } from 'antd';
import parse from 'html-react-parser';
import React, { useMemo } from 'react';

import { useMutation } from '@apollo/client';
import countryList from 'react-select-country-list';
import Button from '~/components/Button';
import { CREATE_CONTACT } from '~/graphql/ContactUs.graphql';
import { isValidEmail } from '~/helpers/regex';
import { CardInfoWrapper } from '../styled';
import { toast } from 'sonner';

const CardInfo = (props) => {
  const { t } = props;
  const options = useMemo(() => countryList().getData(), []);
  const [form] = Form.useForm();
  const [createContact, { loading }] = useMutation(CREATE_CONTACT);

  const roleOptions = [
    { value: 'Founder', label: t('contactUs.form.Founder') },
    { value: 'CEO', label: t('contactUs.form.CEO/CTO') },
    { value: 'PM', label: t('contactUs.form.Product manager') },
    { value: 'DM', label: t('contactUs.form.Development manager') },
    {
      value: 'Individual',
      label: t('contactUs.form.Individual with innovative ideas')
    },
    { value: 'Sale', label: t('contactUs.form.Sales/marketing') },
    { value: 'Other', label: t('contactUs.form.Other') }
  ];

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        createContact({
          variables: {
            input: {
              country: values?.country,
              email: values?.email,
              inquiry: values?.feedBack,
              name: values?.fullName,
              ...(!!values?.phone && { phoneNo: values?.phone }),
              ...(!!values?.role && { role: values?.role }),
              ...(!!values?.company && { company: values?.company })
            }
          },
          onCompleted: () => {
            form.resetFields();
            countDown();
          },
          onError: (err) => {
            toast.error(err?.message);
          }
        });
      })
      .catch(() => {});
  };

  const [modal, contextHolder] = Modal.useModal();
  const countDown = () => {
    let secondsToGo = 3;
    const instance = modal.success({
      title: <div>{t('contactUs.form.Thank you')}</div>,
      content: (
        <div>
          <p>{t('contactUs.form.Thank you for filling out the form. Your submission has been received.')}</p>
          <p>{t('contactUs.form.Our team members reach out to you soon.')}</p>
        </div>
      ),
      footer: null
    });
    const timer = setInterval(() => {
      secondsToGo -= 1;
    }, 1000);
    setTimeout(() => {
      clearInterval(timer);
      instance.destroy();
    }, secondsToGo * 1000);
  };

  return (
    <Spin spinning={loading}>
      <CardInfoWrapper>
        {contextHolder}
        <div className='card-title'>{parse(t('contactUs.title'))}</div>
        <Form
          form={form}
          initialValues={{
            country: 'VN'
          }}
        >
          <Row gutter={[24, 24]} className='mb-4'>
            <Col xl={12} md={12} sm={12} xs={24}>
              <Form.Item
                name='fullName'
                rules={[
                  {
                    required: true,
                    message: t('common.required')
                  }
                ]}
              >
                <Input placeholder={t('contactUs.form.Name')} />
              </Form.Item>
            </Col>
            <Col xl={12} md={12} sm={12} xs={24}>
              <Form.Item name='country'>
                <Select
                  showSearch
                  optionFilterProp='children'
                  filterOption={(input, option) =>
                    (option?.label ?? '').includes(input) ||
                    (option?.label ?? '').toLowerCase().includes(input)
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '')
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? '').toLowerCase())
                  }
                  options={options}
                  placeholder={t('contactUs.form.country')}
                />
              </Form.Item>
            </Col>
            <Col xl={12} md={12} sm={12} xs={24}>
              <Form.Item
                name='email'
                rules={[
                  {
                    required: true,
                    message: t('common.required')
                  },
                  {
                    pattern: isValidEmail(),
                    message: t('common.errorEmail')
                  }
                ]}
              >
                <Input placeholder={t('contactUs.form.email')} />
              </Form.Item>
            </Col>
            <Col xl={12} md={12} sm={12} xs={24}>
              <Form.Item name='phone'>
                <Input placeholder={t('contactUs.form.phone')} />
              </Form.Item>
            </Col>
            <Col xl={12} md={12} sm={12} xs={24}>
              <Form.Item name='company'>
                <Input placeholder={t('contactUs.form.company')} />
              </Form.Item>
            </Col>
            <Col xl={12} md={12} sm={12} xs={24}>
              <Form.Item name='role'>
                <Select
                  options={roleOptions}
                  placeholder={t('contactUs.form.role')}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name='feedBack'
                rules={[
                  {
                    required: true,
                    message: t('common.required')
                  }
                ]}
              >
                <Input.TextArea
                  rows={4}
                  placeholder={t('contactUs.form.feedBack')}
                />
              </Form.Item>
            </Col>
          </Row>

          <Button onClick={handleSubmit} className='btn-send'>
            {t('common.btn.send')?.toUpperCase()}
          </Button>
        </Form>
      </CardInfoWrapper>
    </Spin>
  );
};

export default CardInfo;
