import enLang from './entries/en_US';
import jaLang from './entries/ja_JP';

// import viLang from './entries/vi_VN';
// import twLang from './entries/ta_IN';

const AppLocale = {
  en: enLang,
  ja: jaLang,
  // vi: viLang,
  // cn: twLang
};

export default AppLocale;
