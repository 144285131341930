import { Splide, SplideSlide } from '@splidejs/react-splide';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from '~/common/hooks/useTranslation';
import Button from '~/components/Button';
import SvgIcon from '~/components/SvgIcon';
import { ROUTE_PATH } from '~/routes/route.constant';
import { LayoutCommon } from '~/styles/genaralStyled';
import { services } from './data';
import { OnDemandSoftWrapper } from '../styled';
import { Col, Row } from 'antd';

const OnDemandSoftwareDev = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [serviceIndex, setServiceIndex] = useState(0);

  return (
    <OnDemandSoftWrapper>
      <LayoutCommon>
        <div className='gap-5 flex items-center max-md:flex-col'>
          <div className='w-full md:w-2/3'>
            <div className='text-neutral-800 text-[36px] font-bold self-stretch w-full'>
              {t('other.On-demand Software Development')}
            </div>
            <div className='text-[#262626] text-base leading-7 self-stretch mt-4 w-full'>
              {t(
                'services.service.Our skilled software engineers develop top-notch solutions aligned with your business objectives. From web and mobile apps to immersive games, AR/VR experiences, blockchain, and AI innovations, we turn your vision into reality.'
              )}
            </div>
            <Button
              className='mt-4'
              onClick={() => {
                navigate(ROUTE_PATH.CONTACT_US);
              }}
            >
              {t('common.btn.contactUs')}
            </Button>
          </div>
          <div className='w-full md:w-1/3'>
            <img
              src='/images/servicesImg/on-demand.png'
              width={'100%'}
              alt=''
              className='aspect-[1.39] object-contain object-center w-full h-[342px] max-w-[380px]'
            />
          </div>
        </div>
      </LayoutCommon>
      <div
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/images/servicesImg/toolTechnology.png)`
        }}
        className=''
      >
        <LayoutCommon className='!py-[42px]'>
          <div className='text-white text-4xl font-semibold leading-[58.68px] my-2'>
            {t('services.whyC1')}
          </div>

          <Row gutter={[0, 24]}>
            <Col xl={12} md={12} sm={12} xs={24}>
              <div className='flex flex-col my-6'>
                <div className='flex gap-3'>
                  <SvgIcon name='icon-fe-check' />
                  <div className='text-white text-base font-semibold leading-6'>
                    {t('services.service.On time Delivery')}
                  </div>
                </div>
                <ul className='list-disc pl-7'>
                  <li className='text-zinc-300 text-base leading-6 self-stretch mt-3 max-md:max-w-full'>
                    {t(
                      'services.service.Efficiently manage resources to meet all deadlines effectively.'
                    )}
                  </li>
                  <li className='text-zinc-300 text-base leading-6 self-stretch mt-3 max-md:max-w-full'>
                    {t(
                      'services.service.Real-time daily and weekly reporting for instant updates.'
                    )}
                  </li>
                </ul>
              </div>
              <div className='flex flex-col my-6'>
                <div className='flex gap-3'>
                  <SvgIcon name='icon-fe-check' />
                  <div className='text-white text-base font-semibold leading-6'>
                    {t('services.service.Certified Resources')}
                  </div>
                </div>
                <ul className='list-disc pl-7'>
                  <li className='text-zinc-300 text-base leading-6 self-stretch mt-3 max-md:max-w-full'>
                    {t(
                      'services.service.Product Managers all have more than 8+ years of experience.'
                    )}
                  </li>
                  <li className='text-zinc-300 text-base leading-6 self-stretch mt-3 max-md:max-w-full'>
                    {t(
                      'services.service.80% of developers with more than 5+ years of experience.'
                    )}
                  </li>
                </ul>
              </div>
              <div className='flex flex-col my-6'>
                <div className='flex gap-3'>
                  <SvgIcon name='icon-fe-check' />
                  <div className='text-white text-base font-semibold leading-6'>
                    {t('services.service.High speed')}
                  </div>
                </div>
                <ul className='list-disc pl-7'>
                  <li className='text-zinc-300 text-base leading-6 self-stretch mt-3 max-md:max-w-full'>
                    {t(
                      'services.service.Quick in understanding customer needs and market trends.'
                    )}
                  </li>
                  <li className='text-zinc-300 text-base leading-6 self-stretch mt-3 max-md:max-w-full'>
                    {t(
                      'services.service.Rule number 3: Response in 3 minutes, takes 3 days to propose solutions and 3 months to deliver the project.'
                    )}
                  </li>
                </ul>
              </div>
            </Col>
            <Col xl={12} md={12} sm={12} xs={24}>
              <div className='flex flex-col my-6'>
                <div className='flex gap-3'>
                  <SvgIcon name='icon-fe-check' />
                  <div className='text-white text-base font-semibold leading-6'>
                    {t('services.service.Cost-effective Solution')}
                  </div>
                </div>
                <ul className='list-disc pl-7'>
                  <li className='text-zinc-300 text-base leading-6 self-stretch mt-3 max-md:max-w-full'>
                    {t(
                      'services.service.Competitive cost with high-level resource in Vietnam.'
                    )}
                  </li>
                  <li className='text-zinc-300 text-base leading-6 self-stretch mt-3 max-md:max-w-full'>
                    {t(
                      'services.service.Simplify operations with management tools for effectiveness and ongoing process improvement.'
                    )}
                  </li>
                </ul>
              </div>

              <div className='flex flex-col my-6'>
                <div className='flex gap-3'>
                  <SvgIcon name='icon-fe-check' />
                  <div className='text-white text-base font-semibold leading-6'>
                    {t('services.service.Flexibility in many ways')}
                  </div>
                </div>
                <ul className='list-disc pl-7'>
                  <li className='text-zinc-300 text-base leading-6 self-stretch mt-3 max-md:max-w-full'>
                    {t('services.service.Flexible in providing labors.')}
                  </li>

                  <li className='text-zinc-300 text-base leading-6 self-stretch mt-3 max-md:max-w-full'>
                    {t('services.service.Flexible in terms of working hours.')}
                  </li>

                  <li className='text-zinc-300 text-base leading-6 self-stretch mt-3 max-md:max-w-full'>
                    {t(
                      'services.service.Flexible in allocating resources to accommodate multiple time zones.'
                    )}
                  </li>

                  <li className='text-zinc-300 text-base leading-6 self-stretch mt-3 max-md:max-w-full'>
                    {t(
                      'services.service.Flexible in terms of methodology to follow and 24X7 support.'
                    )}
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </LayoutCommon>
      </div>

      <LayoutCommon>
        <div className='text-neutral-800 text-4xl text-center font-semibold leading-[59px] pt-7 mt-8'>
          {t('other.Categories')}
        </div>

        <Splide
          className='mb-3'
          aria-label='My Favorite Images'
          options={{
            width: '100%',
            // type: 'loop',
            arrows: false,
            perPage: 6,
            lazyLoad: true,
            breakpoints: {
              1000: {
                perPage: 4
              },
              600: {
                perPage: 1
              }
            }
          }}
        >
          {services.map((serviceItem, idx) => {
            return (
              <SplideSlide
                onClick={() => setServiceIndex(idx)}
                key={serviceItem?.category}
              >
                <div className='p-5 h-full '>
                  <div
                    className={`${
                      idx === serviceIndex
                        ? 'text-orange-600 shadow-[0_0_24px_0_rgba(0,0,0,0.12)]'
                        : 'text-[#262626]'
                    } h-full rounded-[10px] flex items-center justify-center cursor-pointer text-center text-base font-bold px-4 py-7`}
                  >
                    {t(
                      `expertises.developmentFields.menu.${serviceItem?.category}`
                    )}
                  </div>
                </div>
              </SplideSlide>
            );
          })}
        </Splide>

        <div className='bg-slate-100 mt-6 px-20 py-3 rounded-xl max-md:px-5'>
          <div className='gap-5 flex max-md:flex-col w-full max-md:gap-0'>
            <div className='flex flex-col justify-center text-black text-lg my-auto max-md:mt-10 w-full md:w-2/3 max-md:ml-0'>
              <ul className='sm:columns-2 list-disc pl-5'>
                {services[serviceIndex].technical.map((item, idx) => {
                  return (
                    <li key={idx} className='leading-[40px] w-full'>
                      {t(`services.service.${item}`)}
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className='flex justify-center  w-full md:w-1/3'>
              <img
                loading='lazy'
                alt=''
                src={services[serviceIndex].img}
                className='object-contain object-center w-full max-w-[326px] overflow-hidden grow max-md:mt-10'
              />
            </div>
          </div>
        </div>

        <div>
          <div className='text-neutral-800 text-4xl text-center font-semibold leading-[59px] mt-8 py-7'>
            {t('services.service.Workflow')}
          </div>

          <Row gutter={[16, 16]} className='mb-10'>
            <Col sm={24} md={12} xl={8} className='flex justify-center'>
              <div className='flex flex-col w-full  max-w-[400px]'>
                <div className='items-stretch flex grow flex-col max-md:mt-7'>
                  <img
                    loading='lazy'
                    alt=''
                    src='https://cdn.builder.io/api/v1/image/assets/TEMP/2ec63bd16c8a1d29d89f93fbabe1e4449c5843dedb8e8c872e72d269510c2e31?'
                    className='aspect-square object-contain object-center w-[100px] overflow-hidden self-center max-w-full'
                  />
                  <div className='text-neutral-800 text-center text-base font-bold leading-6 capitalize self-center mt-4'>
                    {t('services.service.Planning')}
                  </div>
                  <div className='text-neutral-800 text-center text-sm leading-6 mt-2'>
                    {t(
                      'services.service.In the software development kickoff, consider resource allocation, project timeline, and estimated costs.'
                    )}
                  </div>
                </div>
              </div>
            </Col>
            <Col sm={24} md={12} xl={8} className='flex justify-center'>
              <div className='flex flex-col  w-full  max-w-[400px]'>
                <div className='items-stretch flex grow flex-col max-md:mt-7'>
                  <img
                    loading='lazy'
                    alt=''
                    src='https://cdn.builder.io/api/v1/image/assets/TEMP/51a9cad0aba24766bc45b46a12cd836baa313008ed20734dd37dd9ef9aeba656?'
                    className='aspect-square object-contain object-center w-[100px] overflow-hidden self-center max-w-full'
                  />
                  <div className='text-neutral-800 text-center text-base font-bold leading-6 capitalize self-center mt-4'>
                    {t('services.service.Analysis')}
                  </div>
                  <div className='text-neutral-800 text-center text-sm leading-6 mt-2'>
                    {t(
                      'services.service.User Requirements Analytics surveys customer needs, synthesizing information into specs covering UI, function, and non-functional requirements.'
                    )}
                    <br />
                  </div>
                </div>
              </div>
            </Col>
            <Col sm={24} md={12} xl={8} className='flex justify-center'>
              <div className='flex flex-col w-full  max-w-[400px]'>
                <div className='items-center flex grow flex-col max-md:mt-7'>
                  <img
                    loading='lazy'
                    alt=''
                    src='https://cdn.builder.io/api/v1/image/assets/TEMP/0f50cde8c0711d4392607c7527f802aba618545af914886686bfed13e028f5fd?'
                    className='aspect-square object-contain object-center w-[100px] overflow-hidden max-w-full'
                  />
                  <div className='text-neutral-800 text-center text-base font-bold leading-6 capitalize self-stretch mt-4'>
                    {t('services.service.Design')}
                  </div>
                  <div className='text-neutral-800 text-center text-sm leading-6 self-stretch mt-2'>
                    {t(
                      'services.service.After identifying and analyzing requirements, move to the essential Design stage.'
                    )}
                    <br />
                    <br />
                  </div>
                </div>
              </div>
            </Col>
            <Col sm={24} md={12} xl={8} className='flex justify-center'>
              <div className='flex flex-col w-full  max-w-[400px]'>
                <div className='items-center flex grow flex-col max-md:mt-7'>
                  <img
                    loading='lazy'
                    alt=''
                    src='https://cdn.builder.io/api/v1/image/assets/TEMP/06e091226627df46c964f8ec05829051196f7402d594120dd117a910c10939a3?'
                    className='aspect-square object-contain object-center w-[100px] overflow-hidden max-w-full'
                  />
                  <div className='text-neutral-800 text-center text-base font-bold leading-6 capitalize self-stretch mt-4'>
                    {t('services.service.Implementation')}
                  </div>
                  <div className='text-neutral-800 text-center text-sm leading-6 self-stretch mt-2'>
                    {t(
                      'services.service.Developers code based on technical specifications and product requirements agreed upon in the preceding stages.'
                    )}
                  </div>
                </div>
              </div>
            </Col>
            <Col sm={24} md={12} xl={8} className='flex justify-center'>
              <div className='flex flex-col w-full  max-w-[400px]'>
                <div className='items-center flex grow flex-col max-md:mt-7'>
                  <img
                    loading='lazy'
                    alt=''
                    src='/images/listService/step-5.png'
                    className='aspect-square object-contain object-center w-[100px] overflow-hidden max-w-full'
                  />
                  <div className='text-neutral-800 text-center text-base font-bold leading-6 capitalize self-stretch mt-4'>
                    {t('services.service.Testing & Integration')}
                  </div>
                  <div className='text-neutral-800 text-center text-sm leading-6 self-stretch mt-2'>
                    {t(
                      'services.service.Testers receive, test, and collaborate with developers to address bugs, with activities occurring simultaneously or sequentially.'
                    )}
                  </div>
                </div>
              </div>
            </Col>
            <Col sm={24} md={12} xl={8} className='flex justify-center'>
              <div className='flex flex-col w-full  max-w-[400px]'>
                <div className='items-center flex grow flex-col max-md:mt-7'>
                  <img
                    loading='lazy'
                    alt=''
                    src='/images/listService/step-6.png'
                    className='aspect-square object-contain object-center w-[100px] overflow-hidden max-w-full'
                  />
                  <div className='text-neutral-800 text-center text-base font-bold leading-6 capitalize self-stretch mt-4'>
                    {t('services.service.Maintenance')}
                  </div>
                  <div className='text-neutral-800 text-center text-sm leading-6 self-stretch mt-2'>
                    {t(
                      'services.service.After resolving errors, deliver the complete product to the customer. Testing continues during deployment, and a maintenance team promptly addresses post-release issues.'
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </LayoutCommon>
    </OnDemandSoftWrapper>
  );
};

export default OnDemandSoftwareDev;
